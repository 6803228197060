@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Black Ops';
    src: url('../public/fonts/BlackOpsOne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
html, body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  /* Hide scrollbar for Chrome, Safari, and Opera */
  html::-webkit-scrollbar,
  body::-webkit-scrollbar {
    display: none;
  }